<template>
  <div>
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <p class="mt-2">
              <label>
                <span class="mr-2">
                  Recipients (comma separated list of email addresses):
                </span>
                <b-form-input v-model="recipients" placeholder="email" />
              </label>
            </p>
          </b-col>
          <b-col>
            <label class="mr-2">Job SFID / API_ID / NUMBER:</label>
            <b-form-input
              v-model="searchJobId"
              name="searchJobId"
              placeholder="SFID or API Id"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-if="selectedJob" class="m-4">
      <b-col>
        <span class="mr-2">Job Number:</span>
        {{ selectedJob.job.name }}
        <br />
        <span class="mr-2">Partner:</span>
        {{ selectedJob.partner.name }} [{{ selectedJob.partner.id }}]
        <br />
        <span class="mr-2">Brand:</span>
        {{ selectedJob.brand.name }}
        <br />
        <property-object :value="links" :clickable-links="true" />
      </b-col>
    </b-row>

    <div>
      <h5>Select an email type to send:</h5>
      <multiselect
        :multiple="false"
        v-model="selectedTemplate"
        :options="templates"
        track-by="key"
        :custom-label="templateName"
        group-label="group"
        group-values="options"
      >
        <template slot="singleLabel" slot-scope="props">
          <span>
            <code>{{ props.option.key }}</code>
            -
            {{ props.option.description }}
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <span v-if="props.option.$groupLabel">
            <b>{{ props.option.$groupLabel }}</b>
          </span>
          <span v-else>
            <code>{{ props.option.key }}</code>
            - {{ props.option.description }}
          </span>
        </template>
      </multiselect>
      <b-row>
        <b-col>
          <b-form-group label="Template Revision:" class="mt-2">
            <b-form-select
              v-model="revisionSelected"
              :options="revisionOptions"
            >
              <template #first>
                <b-form-select-option :value="null">
                  Automatic
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Template Locale:" class="mt-2">
            <b-form-select v-model="localeSelected" :options="locales">
              <template #first>
                <b-form-select-option :value="null">
                  Automatic
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="selectedTemplate" class="m-4">
        <div class="row">
          <div class="col text-center">
            <h5>Required input for this email</h5>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div
            class="form-group mr-2"
            v-for="(argument, index) in selectedTemplate.args"
            :key="index"
          >
            <label>{{ argument.label }}</label>
            <input
              type="text"
              class="form-control mb-2"
              :placeholder="argument.label"
              v-model="argument.value"
            />
          </div>
        </div>
        <button class="btn btn-primary mb-2" @click.prevent="send">
          Send email
        </button>
      </div>
    </div>
    <messages-list
      :errorMessages="errorMessages"
      :successMessages="successMessages"
    ></messages-list>
  </div>
</template>

<script>
import axios from 'axios';
import { CondOperator, RequestQueryBuilder } from '@dataui/crud-request';
import PropertyObject from '@gid/vue-common/components/inputs/PropertyObject.vue';
import locales from '../../i18n/locales';

export default {
  props: {
    jobId: String,
  },
  components: {
    PropertyObject,
  },
  data() {
    return {
      links: null,
      searchJobId: this.jobId,
      selectedJob: null,
      templateData: {},
      templates: [],
      selectedTemplate: null,
      errorMessages: [],
      successMessages: [],
      recipients: this.$store.state.auth.user.contact.email,
      revisionOptions: [],
      revisionSelected: null,
      localeSelected: null,
      locales,
    };
  },
  watch: {
    searchJobId() {
      this.loadJob();
    },
    selectedTemplate() {
      this.fillArgs();
      this.loadVersions();
    },
  },
  methods: {
    templateName({ key, description }) {
      return `${key} — ${description}`;
    },
    fillArgs() {
      if (this.selectedTemplate) {
        for (let arg of this.selectedTemplate.args) {
          arg.value = this.getInputValue(arg.label);
        }
      }
    },
    loadTemplates() {
      axios.get('/api/admin/test-email/').then((response) => {
        this.templateData = response.data;
        for (let [key, template] of Object.entries(this.templateData)) {
          template.key = key;
          template.args = [];
          for (let index in template.arguments) {
            const argument = template.arguments[index];
            template.args.push({
              label: argument,
              value: '',
            });
          }
          let group = template.key.match(
            /^(customer|partner|brand|accounting)/,
          );
          if (group) {
            group = group[0];
            group = group.charAt(0).toUpperCase() + group.slice(1);
          } else {
            group = 'Misc';
          }
          template.group = group;
        }
        this.templates = Object.values(this.templateData)
          .reduce((options, template) => {
            const group = options.find((t) => t.group === template.group);
            if (group) {
              group.options.push(template);
            } else {
              options.push({
                group: template.group,
                options: [template],
              });
            }
            return options;
          }, [])
          .sort((a, b) => {
            const order = ['Customer', 'Partner', 'Brand'];
            const aIndex = order.indexOf(a.group) || 10000;
            const bIndex = order.indexOf(b.group) || 10000;
            return bIndex - aIndex;
          });
      });
    },
    loadJob() {
      if (this.searchJobId) {
        const params = {
          page_size: 1,
          search: this.searchJobId,
          status: null,
          owner: null,
          include_test_jobs: true,
        };
        return axios
          .get(`/api/admin/jobs`, {
            params,
          })
          .then((response) => {
            this.selectedJob = response.data.data[0];
            this.fillArgs();
            this.loadCustomerAccessLink(this.selectedJob.job.sfid);
          })
          .catch(() => {});
      }
    },
    loadCustomerAccessLink(jobId) {
      return axios
        .get(`/api/admin/test-customer-access-link/${jobId}`)
        .then((response) => {
          this.links = response.data;
          if (location.protocol !== 'https:') {
            for (const key of Object.keys(this.links)) {
              this.links[key] = this.links[key]?.replace(/^https:/i, 'http:');
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    loadVersions() {
      this.revisionOptions = [];
      this.revisionSelected = null;
      const queryString = RequestQueryBuilder.create({
        filter: [
          {
            field: 'path',
            operator: CondOperator.EQUALS,
            value: `email/${this.selectedTemplate.key}.html`,
          },
        ],
      }).query();

      axios.get(`/data-api/template/?${queryString}`).then((response) => {
        this.revisionOptions = response.data.map(({ id }) => id);
      });
    },
    getInputValue(inputName) {
      if (!this.selectedJob) {
        return null;
      }
      switch (inputName) {
        case 'Partner SFID':
          return this.selectedJob.partner.id;
        case 'Jobs SFID':
        case 'Order SFID':
        case 'Offer SFID':
          return this.selectedJob.job.sfid;
      }
      return null;
    },
    send() {
      const template_id = this.selectedTemplate.key;
      const parameters = this.selectedTemplate.args;
      const args = [];
      for (let index in parameters) {
        args.push(parameters[index].value);
      }
      axios
        .post('/api/admin/test-email/', {
          template_name: template_id,
          template_revision: this.revisionSelected,
          locale: this.localeSelected,
          arguments: args,
          recipients: this.recipients,
        })
        .then(() => {
          this.successMessages.push(
            `Email of type ${template_id} sent successfully.`,
          );
        })
        .catch((error) => {
          this.errorMessages.push(error.response.data.errors);
        });
    },
  },
  created() {
    this.loadTemplates();
  },
};
</script>
